.about_container {
    background: linear-gradient(180.09deg, rgba(255, 255, 255, 0) 0.49%, rgba(255, 255, 255, 0.04) 39.06%, rgba(255, 255, 255, 0) 99.92%);
    background-size: 800px;
    background-position: 80% -25%;
}

#about{
    background-image: url('../../assets/bg-simplify-section-desktop.svg');
    background-repeat: no-repeat;
}

#illustration {
    background-image: url('../../assets/bg-simplify-section-desktop.svg');
    background-repeat: no-repeat;
    background-size: 800px;
    background-position: 10% -5%;
}

.hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 2px;
    background-color: rgb(216, 118, 20);
}

.hamburger-middle {
    transform: translateY(7px);
}

.hamburger-bottom {
    transform: translateY(14px);
}

#partner-logo{
    padding-top: 30px;
}

.lamba{
    padding-top: 20px;

}